import { createStore } from "vuex";

export default createStore({
    state:{
        // ----- Lang -----
        lang: navigator.language || navigator.userLanguage,
        // ----- Att themes -----
        mainThemes:['black', 'white'],
        theme:0,
    },
    mutations:{
        // Lang
        setLang(state, data){ state.lang  =data   },
        // Action Teme
        setTheme(state, data){ state.theme =data   },
    },
    actions:{
    },
    modules:{

    }
})